/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";

:root {
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-text-tracking: 0;
  --mat-table-header-headline-weight: normal;
  --mdc-typography-body1-font-size: 14px;
  --mdc-typography-body2-font-size: 12px;
  --mdc-typography-body2-font-weight: normal;
  --mdc-typography-subtitle2-font-weight: normal;
  --mdc-typography-headline1-letter-spacing: 0;
  --mdc-typography-headline2-letter-spacing: 0;
  --mdc-typography-headline3-letter-spacing: 0;
  --mdc-typography-headline4-letter-spacing: 0;
  --mdc-typography-headline5-letter-spacing: 0;
  --mdc-typography-headline6-letter-spacing: 0;
  --mdc-typography-subtitle1-letter-spacing: 0;
  --mdc-typography-subtitle2-letter-spacing: 0;
  --mdc-typography-body1-letter-spacing: 0;
  --mdc-typography-body2-letter-spacing: 0;
  --mdc-typography-caption-letter-spacing: 0;
  --mdc-typography-button-letter-spacing: 0;
  --mdc-typography-overline-letter-spacing: 0;
  --mat-table-row-item-label-text-tracking: 0;
  --mat-toolbar-title-text-tracking: 0;
  --mdc-radio-state-layer-size: 20px;
  --mdc-snackbar-supporting-text-color: black;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-tracking: 0;
}

html {
  --mdc-filled-text-field-label-text-tracking: 0;
  --mdc-outlined-text-field-label-text-tracking: 0;
  --mat-form-field-container-text-tracking: 0;
  --mat-form-field-subscript-text-tracking: 0;
  --mat-optgroup-label-text-tracking: 0;
  --mat-option-label-text-tracking: 0;
  --mat-select-trigger-text-tracking: 0;
  --mat-select-trigger-text-size: 14px;
  --mdc-outlined-text-field-label-text-size: 14px;
  --mdc-filled-text-field-label-text-size: 14px;
  --mat-table-row-item-label-text-size: 12px;
  --mat-menu-item-label-text-tracking: 0;
  --mat-menu-item-label-text-size: 14px;
}

.mat-mdc-header-cell {
  --mat-table-header-headline-tracking: 0;
}

html .mat-mdc-option .mdc-list-item__primary-text {
  font-size: 14px;
}

html .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}

html mat-datepicker-toggle button {
  top: 6px;
}

html .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control,
html .mat-mdc-checkbox .mdc-form-field {
  letter-spacing: 0;
}

/* allows out to overwrite the settings of Angular Material if nothing else helps */
.standard-label-font * {
  font-size: 14px;
  font-weight: normal;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px
}

.price-cell .mat-mdc-text-field-wrapper {
  padding-right: 0;
}

/* add this class to buttons with icons but without text */
button.icon-only .mat-icon {
  margin-left: 0;
  margin-right: 0;
}

.mat-mdc-list-item .mdc-list-item__primary-text {
  letter-spacing: 0;
  font-size: var(--mdc-typography-body1-font-size);
}

.mat-toolbar, .mat-toolbar h1, .mat-toolbar h2, .mat-toolbar h3, .mat-toolbar h4, .mat-toolbar h5, .mat-toolbar h6 {
  letter-spacing: normal;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #ececec;
  --mdc-snackbar-supporting-text-color: black;
  --mat-mdc-snack-bar-button-color: black;
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: "Arial";
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: bold;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 18px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0;
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, .87);
}

.mdc-data-table__header-cell,
.mat-mdc-cell .mat-mdc-form-field,
.mat-mdc-cell .mat-mdc-floating-label {
  font-size: 12px;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white;
}

.mat-mdc-cell .mat-mdc-form-field .mdc-text-field {
  padding-left: 0;
}

.mat-mdc-table .mdc-data-table__cell,
.mat-mdc-table .mdc-data-table__header-cell,
.mat-mdc-table .mat-mdc-form-field-hint-wrapper,
.mat-mdc-table .mat-mdc-form-field-error-wrapper {
  padding-left: 0;
  padding-right: 0;
}

.padding-lr-24 mat-cell:first-of-type,
.padding-lr-24 mat-header-cell:first-of-type,
.padding-lr-24 mat-mdc-footer-cell:first-of-type {
  padding-left: 24px;
  padding-right: 24px;
}

.mat-mdc-form-field-subscript-wrapper {
  font-size: 9px;
}

input.price-cell {
  text-align: end;
}


.mdc-list-item.mdc-list-item--with-one-line {
  height: 42px;
}

.mat-mdc-radio-button label {
  font-size: 14px;
  font-weight: normal;
  width: unset;
}

button.mat-mdc-mini-fab {
  box-shadow: none;
}

h2 {
  width: 100%;
  float: left;
  font-weight: bold;
  padding-bottom: 0.625em;
  padding-top: 1.25em;
}


.full-width {
  width: 100%;
}

body {
  font-family: Arial, sans-serif;
  background: #ccc;
  /* if no gradient support present */
  background: -webkit-linear-gradient(#888, #eee);
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(#888, #eee);
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(#888, #eee);
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(#888, #eee);
  /* Standard syntax */
}

.mat-mdc-simple-snackbar-action {
  color: #dfdfdf;
  border: 1px solid #dfdfdf;
}

.mat-mdc-simple-snackbar-action:hover {
  color: white;
  border: 1px solid white;
}

.mat-mdc-sort-header-button:hover {
  background-color: #ebd187;
  transition: background-color 0.24s;
}

button:disabled {
  background-color: #eee;
  color: #ccc;
  cursor: auto;
}

button.negative-action:hover {
  background-color: #c00;
  transition: background-color 0.24s;
}

button.delete {
  background: white;
  color: black;
}

button.delete:hover {
  color: red;
}

button.delete:disabled {
  background-color: white !important;
  color: #ccc !important;
}

button.back-button {
  width: 120px;
  float: right;
}

button.normalButton {
  background: transparent;
  color: black;
}

button.normalButton:hover {
  background-color: #ebd187;
  transition: background-color 0.24s;
  color: black;
}

button.normalButton:disabled {
  background-color: #eeeeee;
  color: black;
}

button.mat-mdc-mini-fab.tableActionBtn, button.mat-mdc-icon-button.mat-mdc-button-base,
button.mat-mdc-mini-fab.tableActionBtn:visited, button.mat-mdc-mini-mini-fab.mat-mdc-button-base:visited,
button.mat-mdc-mini-fab.tableActionBtn:focus, button.mat-mdc-mini-mini-fab.mat-mdc-button-base:focus {
  background: transparent;
  color: black;
  box-shadow: none;
}

button.mat-mdc-mini-fab.tableActionBtn:hover, button.mat-mdc-icon-button.mat-mdc-button-base:hover {
  background: #4a7eee;
  color: white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

button.mat-mdc-mini-fab.tableActionBtn:disabled {
  box-shadow: none;
  background: transparent;
  color: rgba(0, 0, 0, .26);
}

.mat-mdc-calendar-period-button:hover {
  background: #4a7eee;
  color: white;
}

.textIconButton span span {
  margin-right: 10px;
}

.toolbarSpinner {
  margin-left: 10px;
}

div.mainframe {
  background-color: transparent;
  /*max-width: 1200px;*/
  min-width: 640px;
}

div.mainframe, .side-nav-container {
  min-height: calc(100vh - 20px);
}

.side-nav-container {
  padding: 10px 10px 10px 0;
}

.side-nav {
  max-width: min-content;
}

.side-nav-content {
  overflow: visible !important;
}

.help-block {
  color: #e00;
  display: block;
  margin-top: 2px;
  margin-bottom: 5px;
  margin-left: 10em;
}

/*
label {
  display: inline-block;
  width: 10em;
  height: 2em;
  margin-top: 5px;
  font-weight: bold;
}
 */

label.normal {
  font-weight: normal;
}

label.normal-no-margin {
  font-weight: normal;
  align-items: center;
  margin-top: 0;
  height: auto;
}

label.wide {
  font-weight: normal;
  width: 20em;
}

label.mat-mdc-checkbox-layout, .mat-mdc-radio-label {
  width: auto;
  height: auto;
  margin-top: 0;
  font-weight: normal;
}

input,
select {
  height: 2em;
  font-size: 1em;
  margin-top: 5px;
  padding-left: .4em;
  width: 30em;
}

.numberInput {
  width: 8em;
}

.dateInput {
  width: 20em;
}

.ui-tabview .ui-tabview-panel {
  border: 1px solid #c6c6c6;
}

.ui-widget-header {
  border: 0;
}

input.has-error,
select.has-error {
  background-color: rgba(255, 0, 0, 0.1);
}

th {
  border-bottom: 1px solid #333;
  padding: 10px;
  text-align: left;
}

td {
  border-bottom: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

tbody tr:hover {
  background-color: #ddd
}

tbody tr td {
  overflow: hidden;
}

.error h3 {
  color: #e00;
  background-color: #eee;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
}

.success h4 {
  color: green;
  background-color: #eee;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
}

.error h4 {
  color: #e00;
  background-color: #eee;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
}

.myTable thead th {
  /*	PAGCM-763: Headline missing at prices
  display:none;
  border: none !important;  */
  border-left: 1px;
  background: none !important;
}

td.ui-editable-column {
  background-color: white;
}

td.ui-editable-column.has-error {
  background-color: rgba(255, 0, 0, 0.1);
}

th.ui-state-default.ui-unselectable-text {
  background-color: #eee;
}

span.ui-column-title {
  font-weight: bold;
  color: black;
}

.ui-datatable .ui-datatable-data > tr > td.ui-editable-column {
  padding: 0;
}

label.ui-chkbox-label {
  margin-top: 15px;
  font-weight: normal;
}

.operators.ui-togglebutton.ui-button.ui-state-active {
  width: 110px;
  color: rgb(255, 255, 255);
  background-color: rgb(27, 148, 3);
}

.operators.ui-togglebutton.ui-button {
  width: 110px;
  color: rgb(219, 6, 6);
  background-color: rgb(255, 255, 255);
}

.ui-widget-content.ui-dialog-footer {
  height: 70px;
  border: 1px solid #D5D5D5;
  background: #ffffff;
  color: #222222;
}

.ui-widget-header .ui-button,
.ui-widget-content .ui-button,
.ui-widget.ui-button,
.ui-button,
.ui-widget-header .ui-button:focus,
.ui-widget-content .ui-button:focus,
.ui-widget.ui-button:focus,
.ui-button:focus {
  color: white;
  font-family: "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif;
  font-size: 1em;
  background-color: #3e4146;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: hand;
  float: right;
  margin-right: 10px;
}

.ui-widget-header .ui-button:enabled:hover,
.ui-widget-content .ui-button:enabled:hover,
.ui-widget.ui-button:enabled:hover,
.ui-button:enabled:hover {
  background: #c00;
  border: none;
  transition: background-color 0.24s;
}

.ui-state-highlight {
  background: #c00 !important;
  color: #fff !important;
}

.empty-pass-hint {
  font-size: 12px;
}

.headerToolbar {
  font-size: 20px;
}

.headerToolbarSub {
  font-size: 18px;
}

.toolbarSpinner {
  margin-left: 10px;
}

.headerActionBtn {
  background: transparent;
  color: black;
  float: none;
  padding: 0 5px;
  min-width: 50px;
  margin-right: 5px;
}

.headerSpan {
  padding-right: 10px;
}

.headerActionBtn:hover {
  background-color: #ebd187;
  transition: background-color 0.24s;
}

.mat-mdc-grid-tile .mat-mdc-figure {
  justify-content: flex-start !important;
}

.attribute-name-column {
  min-width: 200px;
}

.default-attribute-table-column {
  min-width: 150px;
}

/*
  copied from mat-mdc-expansion-panel-header to fake the header look
 */
.expansion-table-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  border-radius: inherit;
  height: 64px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}


/*
  the following classes are responsible for material checkbox and radio button related styling
 */
.mat-mdc-checkbox-checked.mat-mdc-accent .mat-mdc-checkbox-background {
  background-color: #ebd187;
}

.mat-mdc-checkbox-checkmark {
  fill: #ebd187;
}

.mat-mdc-checkbox-checkmark-path {
  fill: none;
}

.mat-mdc-checkbox:not(.mat-mdc-checkbox-disabled).mat-mdc-accent .mat-mdc-checkbox-ripple .mat-mdc-ripple-element {
  background-color: #ebd187;
}

.mat-mdc-checkbox .mat-mdc-checkbox-ripple {
  background-color: transparent;
}

.body-margin {
  padding-left: 10px;
}

/**************************************************************************************/

#customerinfo .mat-mdc-paginator-range-label,
#purchaseConditions .mat-mdc-paginator-range-label,
#currencyFactors .mat-mdc-paginator-range-label,
#operators .mat-mdc-paginator-range-label,
#rejectedCdrs .mat-mdc-paginator-range-label,
#invoices .mat-mdc-paginator-range-label,
#charging-cards .mat-mdc-paginator-range-label,
#electricVehicles .mat-mdc-paginator-range-label,
#cdrs-for-revocation-table-area .mat-mdc-paginator-range-label,
#inbox .mat-mdc-paginator-range-label,
#changeRequests .mat-mdc-paginator-range-label, #query-history .mat-mdc-paginator-range-label {
  display: none;
}

#db-query-result .mat-mdc-paginator-range-label {
  display: block;
}

.mat-mdc-paginator-navigation-last:hover,
.mat-mdc-paginator-navigation-first:hover,
.mat-mdc-paginator-navigation-previous:hover,
.mat-mdc-paginator-navigation-next:hover {
  background-color: #ebd187 !important;
  transition: background-color 0.24s !important;
  color: black !important;
}

.mat-mdc-paginator-navigation-last:disabled,
.mat-mdc-paginator-navigation-first:disabled,
.mat-mdc-paginator-navigation-previous:disabled,
.mat-mdc-paginator-navigation-next:disabled {
  background-color: #eeeeee !important;
  box-shadow: none !important;
  color: inherit !important;
}

.section-container {
  margin-top: 10px;
}

.ui-widget-overlay {
  display: none;
}

.section-divider {
  height: 20px;
}

.section-divider-small {
  height: 10px;
}

.button-pane {
  margin: 15px 0 50px 0;
}

.action-buttons-bottom {
  margin-bottom: 10px;
}

.bottom-button {
  float: right;
  margin-right: 10px;
  width: 120px;
}

.bottom-button-right {
  margin-right: 0;
}

mat-mdc-form-field.query-form-field .mat-mdc-form-field-wrapper {
  margin-bottom: 0;
  padding-bottom: 0;
}

mat-mdc-form-field.price-component-form .mat-mdc-form-field-infix {
  width: auto;
}

mat-mdc-form-field.column-filter-form .mat-mdc-form-field-infix {
  width: auto;
}

mat-mdc-form-field.column-filter-select-form .mat-mdc-form-field-wrapper {
  padding-top: 10px;
}


mat-mdc-form-field.radio-button-form {
  width: 100%;
}

.spinner-hor-center {
  margin: 0 auto;
}

.header-spinner {
  margin-left: 10px;
}

div.mat-grid-tile-content {
  justify-content: flex-start;
}

/*
  the following classes are responsible for toggle button related styling
 */
.mat-mdc-button-toggle-button {
  background: transparent;
  color: black;
  height: 36px;
}

.mat-mdc-button-toggle-button:hover {
  background-color: #ebd187;
  transition: background-color 0.24s;
  color: black
}

.mat-mdc-button-toggle-label-content {
  line-height: 36px !important;
}

.mat-mdc-button-toggle-group {
  font-size: 12px;
}

.mat-mdc-button-toggle-checked.mat-mdc-button-toggle-appearance-standard {
  background: black;
  color: white;
}

.mat-mdc-button-toggle-checked.mat-mdc-button-toggle-appearance-standard:hover {
  background-color: #ebd187;
  transition: background-color 0.24s;
  color: black;
}

.tariffCpoSelection.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-flex {
  height: 60px;
}

autocomplete-cpo {
  width: 100%;
}

div.mfa-menu {
  max-width: 290px;
}

.mat-mdc-select-panel .mat-mdc-option.address-selection {
  font-size: 0.8em;
  line-height: 1.5em;
  height: 120px;
  border-bottom: 1px rgba(0, 0, 0, 0.87) solid;
}

.notification-snackbar-panel {
  min-width: 30vw !important;
  max-width: 75vw !important;
  background-color: var(--mdc-snackbar-container-color);
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  min-width: 30vw !important;
  max-width: 75vw !important;
}

.mat-mdc-snack-bar-container {
  background-color: var(--mdc-snackbar-container-color);
  margin-bottom: 24px;
  border-radius: 4px;
}

button.snackbar-close-button {
  background: transparent !important;
}

button.snackbar-close-button:hover {
  background: #ebebeb !important;
}

/*
  the following classes are responsible for country autocomplete-string-select styling
 */
autocomplete-string-select.cp-filter-autocomplete-select div.mat-mdc-form-field-flex {
  height: 51px;
  background-color: white;
}

autocomplete-string-select.cp-details-autocomplete-select div.mat-mdc-form-field-flex {
  min-width: 280px;
  background-color: white;
}

.mat-mdc-row:hover autocomplete-string-select.cp-details-autocomplete-select div.mat-mdc-form-field-flex,
autocomplete-string-select.cp-details-autocomplete-select div.mat-mdc-form-field-flex:hover {
  background-color: #dddddd;
}

.mat-mdc-mini-fab svg-icon {
  position: relative;
  top: 2px;
}

/*************************************************************/

.filter-column-header-cell mat-select,
html body .font-14 {
  font-size: 14px !important;
}

.date-range-column-filter-toggle > .mat-mdc-icon-button.mat-mdc-button-base {
  width: 44px;
  height: 44px;
  padding-top: 10px;
  padding-left: 10px;
}
